import React from "react";
import Header from "../header.js";
import Footer from "../footer.js";
import SiteMetadata from '../site-metadata.js';

export default ({ children, pageInfo }) => (
  <React.Fragment>
    <SiteMetadata pageInfo={pageInfo}  />
    <Header />
      {children}
    <Footer />
  </React.Fragment>
)
