import React from "react";
import Helmet from "react-helmet";

export default ({pageInfo}) =>  {
    return (
        <Helmet>
            <html lang="en" />
            {/* <!-- Disable browser cache */}
            <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
            <meta http-equiv="Pragma" content="no-cache" />
            <meta http-equiv="Expires" content="0" />

            {/* <!-- Primary Meta Tags --> */}
            <title>{pageInfo.title}</title>
            <meta name="title" content={pageInfo.title} />
            <meta name="description" content={pageInfo.description} />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={pageInfo.url} />
            <meta property="og:title" content={pageInfo.title} />
            <meta property="og:description" content={pageInfo.description} />
            <meta property="og:image" content="https://documentation.elasticpath.com/ep-logo-full.png" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={pageInfo.url} />
            <meta property="twitter:title" content={pageInfo.title} />
            <meta property="twitter:description" content={pageInfo.description} />
            <meta property="twitter:image" content="https://documentation.elasticpath.com/ep-logo-full.png" />

            { /* Polifyll for supporting webcomponent in older browsers */}
            <script defer src="https://documentation.elasticpath.com/_lib/ep-webcomponent/webcomponents-loader.js"></script>
            <script defer src="https://documentation.elasticpath.com/_lib/ep-webcomponent/public/EpSiteNav.js"></script>
            <script defer src="https://documentation.elasticpath.com/_lib/ep-webcomponent/public/EpSiteFooter.js"></script>
        </Helmet>
    )
}
